import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faGear,
  faLocationDot,
  faPhone,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../context/slices/usersSlices";

const Users = () => {
  const [openPopUp, setOpenPopUp] = useState({ status: false, id: "" });
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  async function deleteUser(id) {
    try {
      const response = await axiosInstance.delete(`/api/users/${id}`);
      if (response.data.success) {
        toast.success(response.data.message);
        dispatch(fetchUsers());
        setOpenPopUp({ status: false, id: "" });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  if (!users || users.length <= 1)
    return <div className="spinner">...loading</div>;

  return (
    <div className="users">
      {openPopUp.status && (
        <div className="popup">
          <div className="popup-box">
            <h5>This action can't be undo you still want to delete?</h5>
            <div className="button-options">
              <button
                className="cancel"
                onClick={() => setOpenPopUp({ status: false, id: "" })}
              >
                Cancel
              </button>
              <button
                className="delete"
                onClick={() => {
                  deleteUser(openPopUp.id);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <table>
        <thead>
          <tr>
            <th>
              <FontAwesomeIcon icon={faUser} />
              Name
            </th>
            <th>
              <FontAwesomeIcon icon={faLocationDot} />
              Location
            </th>
            <th>
              <FontAwesomeIcon icon={faEnvelope} />
              Email
            </th>
            <th>
              <FontAwesomeIcon icon={faPhone} />
              Phone
            </th>
            <th>
              <FontAwesomeIcon icon={faGear} />
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            const { address } = user;
            return (
              <tr key={index}>
                <td className="user-name">{user.name}</td>
                <td className="address">
                  <div>
                    <span className="city">{address?.city}</span>
                  </div>
                </td>
                <td>{user.email}</td>
                <td>{user.address?.phone}</td>
                <td className="options">
                  <div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() =>
                        setOpenPopUp({ status: true, id: user._id })
                      }
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default Users;
