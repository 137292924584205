import { configureStore } from "@reduxjs/toolkit";
import productSlice from "./slices/productSlice";
import adminSlice from "./slices/adminSlice";
import orderSlice from "./slices/orderSlice";
import usersSlice from "./slices/usersSlices"
const store = configureStore({
  reducer: {
    products: productSlice,
    admin: adminSlice,
    orders: orderSlice,
    users: usersSlice,
  },
});

export default store;
