import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import config from "../config/config";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchProduct } from "../context/slices/productSlice";

const Products = () => {
  const dispatch = useDispatch();
  const {products} = useSelector((state)=>state.products)
  const [openPopUp, setOpenPopUp] = useState({ status: false, id: "" });
  const navigator = useNavigate();
 
  async function deleteProduct(id) {
    try {
      const response = await axiosInstance.post("api/products/remove", {
        id,
      });
      if (response.data.success) {
        toast.success(response.data.message);
        dispatch(fetchProduct())
        setOpenPopUp({ status: false, id: "" });
      }
    } catch (error) {}
  }


  if (products.length < 1) {
    return <div>...loading</div>;
  }

  return (
    <div className="products">
      {openPopUp.status && (
        <div className="popup">
          <div className="popup-box">
            <h5>This action can't be undo you still want to delete?</h5>
            <div className="button-options">
              <button
                className="cancel"
                onClick={() => setOpenPopUp({ status: false, id: "" })}
              >
                Cancel
              </button>
              <button
                className="delete"
                onClick={() => {
                  deleteProduct(openPopUp.id);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th className="size-head">Size & color</th>
            <th>Options</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => {
            return (
              <tr className="product" key={index}>
                <td className="text-center">
                  <img
                    src={
                      config.BASE_URL + product.colorVariants[0].images[0].url
                    }
                    alt=""
                  />
                </td>
                <td className="product-name">{product.name}</td>
                <td className="sizes-color">
                  {product.colorVariants.map((color, index) => (
                    <div className="color-size-holder" key={index}>
                      <div
                        className="color"
                        style={{ backgroundColor: color.color }}
                      ></div>
                      <div className="sizes">
                        {color.sizes.map((size, index) => (
                          <span key={index}>
                            {size.size} {size.quantity}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </td>
                <td className="options">
                  <div>
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      onClick={() => navigator(`/products/${product._id}`)}
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      onClick={() =>
                        setOpenPopUp({ status: true, id: product._id })
                      }
                    />
                  </div>
                </td>
                <td className="price">AED {product.price}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
{
  /* <li className="color" key={index} style={{backgroundColor:`${item.color}`}}></li> */
}
export default Products;
