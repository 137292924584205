import { useState } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Login from "./pages/Login";
import ProductForm from "./pages/ProductForm";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import { ToastContainer } from "react-toastify";
import ProductInfo from "./pages/ProductInfo";
import Users from "./pages/Users";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdmin } from "./context/slices/adminSlice";
import OverView from "./pages/OverView";
import { fetchUsers } from "./context/slices/usersSlices";
import { fetchOrders } from "./context/slices/orderSlice";
import { fetchProduct } from "./context/slices/productSlice";

function App() {
  const { username, status, role } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { usersStatus } = useSelector((state) => state.users);
  const { ordersStatus } = useSelector((state) => state.orders);
  const { productStatus } = useSelector((state) => state.products);
  useEffect(() => {
    if (status !== "succeesfull") {
      dispatch(fetchAdmin());
    }
    if (
      usersStatus !== "successfull" ||
      ordersStatus !== "successfull" ||
      productStatus !== "successfull"
    ) {
      dispatch(fetchUsers());
      dispatch(fetchOrders());
      dispatch(fetchProduct());
    }
  }, [status]);

  if (!username || role !== "admin") {
    return <Login />;
  }

  return (
    <>
      <ToastContainer autoClose={1200} />
      <Navbar />
      <div className="app-content">
        <Sidebar />
        <div className="home">
          <Routes>
            <Route path="/" element={<OverView />} />
            <Route path="/add" element={<ProductForm />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<ProductInfo />} />
            <Route path="/users" element={<Users />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
