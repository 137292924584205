import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { toast } from "react-toastify";

const ProductInfo = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    price: "",
    discount: 0,
    description: "",
    category: "",
    colorVariants: [],
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    price: "",
    discount: "",
    description: "",
    category: "",
  });

  const product = products.find((product) => product._id === id);

  useEffect(() => {
    async function getProducts() {
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/api/products/all`
      );
      const data = response.data.data;
      setProducts(data);
    }
    getProducts();
  }, []);

  // Set initial form data with size defaults
  useEffect(() => {
    if (product) {
      setFormData({
        id: product._id,
        name: product.name || "",
        price: product.price || "",
        discount: product.discount || 0,
        description: product.description || "",
        category: product.category || "",
        colorVariants: product.colorVariants.map((variant) => {
          // Default sizes
          const defaultSizes = [
            { size: "S", quantity: 0, checked: false },
            { size: "M", quantity: 0, checked: false },
            { size: "L", quantity: 0, checked: false },
            { size: "XL", quantity: 0, checked: false },
          ];

          // Merge with existing sizes without duplicates
          const mergedSizes = defaultSizes.map((defaultSize) => {
            const existingSize = variant.sizes.find(
              (size) => size.size === defaultSize.size
            );
            return existingSize
              ? {
                  ...existingSize,
                  checked: existingSize.quantity > 0,
                }
              : defaultSize;
          });

          return {
            color: variant.color,
            images: variant.images, // Keep images as is
            sizes: mergedSizes,
          };
        }),
      });
    }
  }, [product]);


  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle size change
  const handleSizeChange = (colorIndex, sizeIndex, e) => {
    const { name, value, checked } = e.target;
    const updatedColors = [...formData.colorVariants];

    // Update the specific size
    updatedColors[colorIndex].sizes[sizeIndex] = {
      ...updatedColors[colorIndex].sizes[sizeIndex],
      [name]: name === "checked" ? checked : parseInt(value),
      quantity: name === "checked" && checked ? 1 : parseInt(value),
    };

    setFormData((prevData) => ({
      ...prevData,
      colorVariants: updatedColors,
    }));
  };

  // Validate form
  const validateForm = () => {
    let valid = true;

    const newErrors = {
      name: "",
      price: "",
      discount: "",
      description: "",
      category: "",
    };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };


  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/api/products/edit`, formData);

      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  if (!product) return <div>...loading</div>;

  return (
    <div className="product-info">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="input-holder">
          <label htmlFor="name">Name:</label>
          <input
            onChange={handleInputChange}
            className="main-input w-50"
            type="text"
            name="name"
            id="name"
            value={formData.name}
          />
          {formErrors.name && <span>{formErrors.name}</span>}
        </div>
        <div className="input-holder">
          <label htmlFor="price">Price:</label>
          <input
            onChange={handleInputChange}
            className="main-input w-50"
            type="number"
            name="price"
            id="price"
            value={formData.price}
          />
        </div>
        <div className="input-holder">
          <label htmlFor="discount">Discount:</label>
          <input
            onChange={handleInputChange}
            className="main-input w-50"
            type="number"
            name="discount"
            id="discount"
            value={formData.discount}
          />
        </div>
        <div className="input-holder">
          <label htmlFor="description">Description:</label>
          <textarea
            onChange={handleInputChange}
            className="main-input w-50"
            name="description"
            id="description"
            value={formData.description}
          />
        </div>
        <div className="category-holder">
          <label htmlFor="category">Category:</label>
          <select
            onChange={handleInputChange}
            name="category"
            id="category"
            value={formData.category}
          >
            <option value="scrunchies">scrunchies</option>
            <option value="accessories">Accessories</option>
            <option value="head-band">head-band</option>
            <option value="hijabs">Hijabs</option>
            <option value="national-day">national-day</option>
            <option value="sales">sales</option>
          </select>
        </div>
        <div className="color-holder">
          <label>colorVariants:</label>
          {formData.colorVariants.map((variant, colorIndex) => (
            <div key={colorIndex} className="color-box">
              <div className="input-holder">
                <div
                  className="color"
                  style={{ backgroundColor: variant.color }}
                ></div>
                <input
                  type="text"
                  name="color"
                  className="main-input"
                  value={variant.color}
                  readOnly
                />
              </div>
              <div className="size-holder">
                {variant.sizes.map((size, sizeIndex) => (
                  <div key={sizeIndex} className="size-box">
                    <label>
                      <input
                        type="checkbox"
                        name="checked"
                        checked={size.checked}
                        onChange={(e) =>
                          handleSizeChange(colorIndex, sizeIndex, e)
                        }
                      />
                      {size.size}
                    </label>
                    <input
                      type="number"
                      name="quantity"
                      className="main-input"
                      value={size.quantity || parseInt("0")}
                      onChange={(e) =>
                        handleSizeChange(colorIndex, sizeIndex, e)
                      }
                      disabled={!size.checked}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <button type="submit" className="main-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ProductInfo;
