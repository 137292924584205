import React from "react";
import { assets } from "../assets/assets";
import {Container} from "react-bootstrap"
import { useSelector } from "react-redux";
const Navbar = () => {
  const {username,status} = useSelector((state)=>state.admin)
  

  if (!username) return <div>...loading</div>
  
  return (
    <header className="navbar">
      <Container>
        <img src={assets.letter_logo} alt="" className="logo" />
        <span className="welcome">
          Welcome <h2>{username}</h2>
        </span>
      </Container>
    </header>
  );
};

export default Navbar;
