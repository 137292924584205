import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const fetchAdmin = createAsyncThunk("user/fetchAdmin", async () => {
  try {
    const response = await axiosInstance.get("/api/users/profile");
    const data = response.data;
    if (data.success) {
      return data.user;
    }
  } catch (error) {
  }
});

const adminSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    status: "idle",
    error: null,
    role:"",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdmin.fulfilled, (state, action) => {
        state.status = "succeesfull";
        state.username = action.payload?.name;
        state.role = action.payload?.role
      })
      .addCase(fetchAdmin.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default adminSlice.reducer;
