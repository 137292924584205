import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const fetchOrders = createAsyncThunk("order/fetchOrders", async () => {
  try {
    const response = await axiosInstance.get("/api/order/list");
    const data = response.data;
    if (data.success) {
      return data;
    }
  } catch (error) {}
});

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "successfull";
        state.orders = action.payload.data;
      })
      .addCase(fetchOrders.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default orderSlice.reducer;
