import { createAsyncThunk, createReducer, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-toastify";
// import axios from "axios";

export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async () => {
    try {
      const response = await axiosInstance.get("api/products/all");
      if (response.data.success) {
        return response.data
      }
    } catch (error) {
      toast.error(error.response.data.message);
      localStorage.clear()
      location.assign("http://localhost:5173/");
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    status: "idle",
    error: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.status = "successfull";
        state.products = action.payload.data;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.error = action.payload.data.message;
        state.status = "failed";
      });
  },
});

// export const {} = productSlice.actions
export default productSlice.reducer;
