import { faCoins, faCubes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const OverView = () => {
  const { users } = useSelector((state) => state.users);
  const { orders } = useSelector((state) => state.orders);
  const { products } = useSelector((state) => state.products);

  if (
    !users ||
    users.length < 1 ||
    !orders ||
    orders.length < 1 ||
    !products ||
    products.length < 1
  )
    return <div>...loading</div>;

  const totalAmount = orders.reduce((acc, curr) => acc + curr.amount, 0);

  const delivery = 25;

  return (
    <div className="overview">
      <div className="boxs-container">
        <div className="box total-box">
          <h5 className="box-header">
            <FontAwesomeIcon icon={faCoins} />
            Amount
          </h5>
          <div className="inner-container">
            <div>
              <p>Total </p>
              <span className="total-amount">
                AED {(totalAmount - delivery * orders.length).toLocaleString()}
              </span>
            </div>
            <div>
              <p>last order</p>
              <span> AED {orders[0].amount - 25}</span>
            </div>
            <div>
              <p>Recent order</p>
              <span> AED {orders[1].amount - 25}</span>
            </div>
          </div>
        </div>
        <div className="box users-box">
          <h5 className="box-header">
            <FontAwesomeIcon icon={faUsers} />
            Users
          </h5>
          <div className="inner-container">
            <div>
              <p>Total </p>
              <span className="total-users">{users.length}</span>
            </div>
            <div>
              <p>last user </p>
              <span className="total-users">{users[0].name}</span>
            </div>
            <div>
              <p>recent user </p>
              <span className="total-users">{users[1].name}</span>
            </div>
          </div>
        </div>
        <div className="box products-box">
          <h5 className="box-header">
            <FontAwesomeIcon icon={faCubes} />
            Products
          </h5>
          <div className="inner-container">
            <div>
              <p>Total</p>
              <span className="total-products">{products.length}</span>
            </div>
            <div>
              <p>last added</p>
              <span>{products[0].name}</span>
            </div>
            <div>
              <p>recent added</p>
              <span>{products[1].name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OverView;
